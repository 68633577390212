import {ICategory, ICategoryListItem} from '../types/category';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IFilterConfigDTO} from '../types/galleryTypes';
import {RouterPrefix} from '@wix/wixstores-client-core';
import {Experiments} from '../constants';

export type CategoriesData = {
  currentCategory: ICategory;
  listHeaderCategory: ICategoryListItem;
  categoryList: ICategoryListItem[];
  backButtonUrl: string;
};

export class CategoriesService {
  public visibleCategories: ICategoryListItem[];
  private readonly currentCategory: ICategory;
  private readonly allCategories: ICategoryListItem[];
  private readonly allProductsCategoryId: string;
  private readonly siteStore: SiteStore;
  private categoryListConfig: IFilterConfigDTO;

  private idToCategoryMap: {[id: string]: ICategoryListItem} = {};

  constructor({
    currentCategory,
    allCategories,
    allProductsCategoryId,
    siteStore,
  }: {
    currentCategory: ICategory;
    allCategories: ICategoryListItem[];
    allProductsCategoryId: string;
    siteStore: SiteStore;
  }) {
    this.siteStore = siteStore;
    this.allCategories = allCategories;
    this.allProductsCategoryId = allProductsCategoryId;

    this.currentCategory = this.siteStore.experiments.enabled(Experiments.GetCurrentCategorySeparately)
      ? (this.currentCategory = this.addUrl(currentCategory))
      : currentCategory;
  }

  private getSubcategories(categoryId: string): ICategoryListItem[] {
    /* istanbul ignore next: hard to test */
    if (!categoryId) {
      return [];
    }

    return this.visibleCategories.filter((category) => category.parentCategoryId === categoryId);
  }

  public getCategorySeoData(categoryId: string, parentCategoryId: string) {
    return {
      categoryDirectParentName: this.allCategories.find((category) => parentCategoryId === category.id)?.name,
      categoryParentNames: this.getCategoryParentNames(categoryId),
    };
  }

  private getCategoryParentNames(categoryId: string): string[] {
    const categoryMap = new Map<string, ICategoryListItem>();
    this.allCategories.forEach((category) => categoryMap.set(category.id, category));

    function findParentNames(currentId: string): string[] {
      const currentCategory = categoryMap.get(currentId);
      if (!currentCategory?.parentCategoryId) {
        return [];
      }

      const parentCategory = categoryMap.get(currentCategory.parentCategoryId);
      /* istanbul ignore next: hard to test - maybe not a valid case but adding guard in case */
      if (!parentCategory) {
        return [];
      }

      return [parentCategory.name, ...findParentNames(parentCategory.id)];
    }

    return findParentNames(categoryId);
  }

  private getCategoryById(categoryId: string): ICategoryListItem {
    if (!categoryId) {
      return null;
    }

    return this.idToCategoryMap[categoryId];
  }

  private getRootLevelCategories(): ICategoryListItem[] {
    return this.visibleCategories.filter((category) => !category.parentCategoryId);
  }

  private getCategoryList(): ICategoryListItem[] {
    const subcategories = this.getSubcategories(this.currentCategory.id);
    if (subcategories.length) {
      return subcategories;
    }

    const parent = this.getCategoryById(this.currentCategory.parentCategoryId);
    if (parent) {
      return this.getSubcategories(parent.id);
    }

    return this.getRootLevelCategories();
  }

  private getListHeaderCategory(): ICategoryListItem {
    const subcategories = this.getSubcategories(this.currentCategory.id);
    if (subcategories.length) {
      return this.getCategoryById(this.currentCategory.id);
    }

    const parent = this.getCategoryById(this.currentCategory.parentCategoryId);
    if (parent) {
      return parent;
    }

    return null;
  }

  private getBackButtonUrl() {
    const parent = this.getCategoryById(this.currentCategory.parentCategoryId);
    return parent?.categoryUrl;
  }

  public getProps(): CategoriesData {
    return {
      currentCategory: this.currentCategory,
      listHeaderCategory: this.getListHeaderCategory(),
      categoryList: this.getCategoryList(),
      backButtonUrl: this.getBackButtonUrl(),
    };
  }

  public updateVisibleCategories({
    categoryListConfig,
    shouldUseCategoryListConfig,
  }: {
    categoryListConfig?: IFilterConfigDTO;
    shouldUseCategoryListConfig: boolean;
  }) {
    if (categoryListConfig) {
      this.categoryListConfig = categoryListConfig;
    }

    let categories = [...this.allCategories];
    if (shouldUseCategoryListConfig && this.categoryListConfig !== undefined) {
      const sortedIds = [];
      const selectedCategoriesFromConfig = this.categoryListConfig.selected.reduce((obj, item) => {
        obj[item.id] = null;
        sortedIds.push(item.id);
        return obj;
      }, {});

      categories = categories
        .filter((c) => c.visible && c.id in selectedCategoriesFromConfig)
        .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
    } else {
      categories = categories.filter((c) => c.visible).sort((a, b) => a.name.localeCompare(b.name));
      const defaultCategoryIndex = categories.findIndex((c) => c.id === this.allProductsCategoryId);
      if (defaultCategoryIndex !== -1) {
        categories.unshift(categories.splice(defaultCategoryIndex, 1)[0]);
      }
    }

    if (!this.siteStore.experiments.enabled(Experiments.NewCategoryList)) {
      this.visibleCategories = categories.map((c) => {
        return {
          ...c,
          categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${c.slug}`,
        };
      });
      return;
    }

    this.visibleCategories = categories.map(this.addUrl);
    this.visibleCategories.sort((a, b) => a.parentCategoryIndex - b.parentCategoryIndex);
    this.idToCategoryMap = Object.fromEntries(this.visibleCategories.map((c) => [c.id, c]));
  }

  private readonly addUrl = (category) => {
    return {...category, categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${category.slug}`};
  };

  public getCategoryBySlug(slug?: string): ICategory {
    return (this.allCategories.find((c) => c.slug === slug) as ICategory) ?? (this.visibleCategories[0] as ICategory);
  }
}
